import { useEffect } from "react";
import { AppLayout } from "@amzn/awsui-components-react/polaris";
import { PAGE_TITLES } from "src/app/constants";
import SideMenu from "src/components/sideMenu/SideMenu";
import DocumentsManagement from "src/views/admin/documentsManagement/DocumentsManagement";

const DocumentsManagementPage = () => {
  /**
   * Changes the browser tab/menu bar title to Nucleus Sites Admin Panel when page loads
   */
  useEffect(() => {
    document.title = PAGE_TITLES.SITE_CONFIGURATION;
  }, []);
  return (
    <AppLayout
      content={<DocumentsManagement />}
      navigation={<SideMenu />}
      toolsHide={true}
      contentType="dashboard"
      footerSelector="#app-footer"
      headerSelector="#app-header"
      ariaLabels={{
        navigationClose: "Close side menu",
        navigationToggle: "Open Side menu",
      }}
    />
  );
};
export default DocumentsManagementPage;
