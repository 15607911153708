import { FormFieldType, UploadFormField } from "../types";
import {
  Box,
  FileUpload,
  FileUploadProps,
  SpaceBetween,
  Spinner,
  TextContent,
} from "@amzn/awsui-components-react";
import React from "react";
import { transformSerializedFilesToFiles } from "../FileUpload/FileUploadUtilities";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import {
  ASSOCIATE_INFORMATION_CONSTANTS,
  FILE_ATTACHMENTS_CONSTANTS,
  INCIDENT_INFORMATION_CONSTANTS,
  INCIDENT_TIME_AND_PLACE_CONSTANTS,
} from "../constants";
import SimLinkGenerator from "src/components/simLinkGenerator/SimLinkGenerator";
import { MAX_FILENAME_SIZE } from "../FileUpload/constants";
import { SIM_TYPES } from "src/components/simLinkGenerator/types";

const renderFileUpload = (
  field: FormFieldType,
  onFileUpload: (
    { detail }: { detail: FileUploadProps.ChangeDetail },
    field: FormFieldType
  ) => void
) => {
  //Redux state
  const { allFormStates, uploadingFile } = useAppSelector(
    (state: RootState) => state.forms
  );
  const { amazonClaimReferenceId } = useAppSelector(
    (state: RootState) => state.app
  );
  const name = field.config.uniqueName || field.config.name;
  const fieldConfig = field.config as UploadFormField;

  const renderAlertContents = (simType: SIM_TYPES, claimModel: any) => (
    <TextContent>
      <SpaceBetween direction="vertical" size="xxxs">
        <div>
          Click on &quot;Choose File(s)&quot; or drag and drop to upload files.
        </div>
        <div>
          Supported formats are .jpeg, .jpg, .png, .doc, .pdf, .gif, .rtf, .tif,
          .txt, .docx. Each file cannot exceed 6MB and a total of 29MB for all
          files combined. File names cannot be longer than {MAX_FILENAME_SIZE}{" "}
          characters.
        </div>
        <div>
          If you want to upload a video or if the file type is not supported or
          exceeds the size limit, kindly follow the existing process by creating
          a{" "}
          <SimLinkGenerator
            simType={simType}
            claimModel={claimModel}
            target="_blank"
            ariaLabel="Press this link to open a new tab where you will be able to create a ticket to upload videos or files that exceed the size limit."
          >
            ticket.{" "}
          </SimLinkGenerator>
          To initiate the process, please complete the claim submission to
          obtain a unique claim number. Ensure that you enter this claim number
          while creating the ticket.
        </div>
      </SpaceBetween>
    </TextContent>
  );

  if (field.config.name === FILE_ATTACHMENTS_CONSTANTS.INITIAL_REPORT_FORM) {
    const associateInformation =
      allFormStates[ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME];
    const incidentInformation =
      allFormStates[INCIDENT_INFORMATION_CONSTANTS.FORM_NAME];
    const fieldsForSimGeneration = {
      associateEmployeeId:
        associateInformation?.[
          ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_EMPLOYEE_ID
        ]?.value,
      associateAlias:
        associateInformation?.[ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_ALIAS]
          ?.value,
      associateFirstName:
        associateInformation?.[
          ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_FIRST_NAME
        ]?.value,
      associateLastName:
        associateInformation?.[
          ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_LAST_NAME
        ]?.value,
      incidentSiteName:
        incidentInformation?.[
          INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME
        ]?.value ||
        associateInformation?.[
          ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_NAME
        ]?.value,
      amazonClaimReferenceId,
    };
    if (field.config.customAlert) {
      field.config.customAlert.value = renderAlertContents(
        SIM_TYPES.FILE_ATTACHMENT_ISSUE,
        fieldsForSimGeneration
      );
    }
  }
  return (
    <div>
      <FileUpload
        onChange={(e: any) => onFileUpload(e, field)}
        value={transformSerializedFilesToFiles(field.state.value)}
        i18nStrings={{
          uploadButtonText: (multiple: boolean) =>
            multiple ? "Choose files" : "Choose file",
          dropzoneText: (multiple: boolean) =>
            multiple ? "Drop files to upload" : "Drop file to upload",
          removeFileAriaLabel: (fileIndex: number) =>
            `Remove file ${fileIndex + 1}`,
          limitShowFewer: "Show fewer files",
          limitShowMore: "Show more files",
          errorIconAriaLabel: "Error",
        }}
        multiple={fieldConfig.multiple}
        showFileLastModified
        showFileSize
        tokenLimit={100}
        data-testid={fieldConfig.uniqueName || fieldConfig.name}
        key={`${name}-fileUpload`}
        controlId={fieldConfig.name}
      />
      {uploadingFile === fieldConfig.name ? (
        <SpaceBetween direction="horizontal" size="xs">
          <Spinner variant="disabled" />
          <Box color="text-body-secondary" fontSize="body-m">
            Uploading, please wait...
          </Box>
        </SpaceBetween>
      ) : null}
    </div>
  );
};

export default renderFileUpload;
