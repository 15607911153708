import { Box, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import React from "react";

const RetrievingFileList = () => {
  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Box variant="awsui-key-label" data-testid="retrievingFilesMessage">
        Retrieving file list, please wait...
      </Box>
      <Spinner></Spinner>
    </SpaceBetween>
  );
};
export default RetrievingFileList;
