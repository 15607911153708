import { CreateWcFileUploadUrlInput } from "@amzn/ttechclaimintakeservice-client";
import { MetricsPublisher } from "../../metrics/metrics";
import { createWcFileUploadUrlService } from "./createWcFileUploadUrl";

const RADIX_DECIMAL = 10;

export async function uploadWcFile(
  input: CreateWcFileUploadUrlInput,
  file: File
) {
  const metricsPublisher = new MetricsPublisher("api.createWcFileUploadUrl");
  try {
    const response = await createWcFileUploadUrlService(input);
    console.log(" URL: ", response.uploadUrl);

    if (typeof response.uploadUrl !== "string") {
      throw new Error("Invalid URL");
    }
    // Valid URL attempt to upload the file
    return await attemptFileUpload(response.uploadUrl, file);
  } catch (error: any) {
    metricsPublisher.publishFileUploadURLRetryMetrics();
    console.error("Error requesting URL: ", error);
    throw error;
  }
}

async function attemptFileUpload(url: string, file: File) {
  let response = await uploadFiles(url, file);
  if (response.ok) {
    return response.ok;
  } else {
    // If file upload fails the first time make a second attempt.
    // Then return status with either success/failure.
    console.error("Error uploading file: ", response);

    response = await uploadFiles(url, file);

    return response.status;
  }
}

async function uploadFiles(url: string, file: File) {
  const metricsPublisher = new MetricsPublisher("api.uploadWcFile");
  const metricsPublisherError = new MetricsPublisher("api.uploadWcFile.error");
  const response = await fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-type": file.type,
    },
  });
  if (response.ok) {
    metricsPublisher.publishFileUploadSuccessMetrics();
    metricsPublisher.publishFileUploadLatencyMetrics();
    return response;
  } else {
    metricsPublisher.publishFileUploadFailureMetrics();
    metricsPublisher.publishFileUploadRetryMetrics();
    metricsPublisherError.publishFileUploadError(
      response.status.toString(RADIX_DECIMAL)
    );
    console.log("Error uploading file: ", response);
    throw new Error("Error uploading file");
  }
}
