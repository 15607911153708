import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import { ROUTES } from "./app/constants";
import ClaimIntakeFormPage from "./pages/ClaimIntakeFormPage";
import MedicalPanelDataPage from "./pages/MedicalPanelDataPage";
import SiteConfigurationPage from "./pages/SiteConfigurationPage";
import { loader as homePageLoader } from "./pages/loaders/HomePageLoader";
import { loader as siteConfigurationLoader } from "./pages/loaders/SiteConfigurationPageLoader";
import { loader as claimIntakeFormLoader } from "./pages/loaders/ClaimIntakeFormPageLoader";
import { loader as claimsHistoryLoader } from "./pages/loaders/ClaimsHistoryPageLoader";
import ClaimsHistoryPage from "./pages/ClaimsHistoryPage";
import MySubmittedClaimsPage from "./pages/MySubmittedClaimsPage";
import { User } from "./auth/types/interfaces";
import WorkersCompensationDocumentsPage from "./pages/WorkersCompensationDocumentsPage";
import DocumentsManagementPage from "./pages/DocumentsManagementPage";

export const createAppRouter = (user: User) => {
  return createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
      errorElement: <ErrorPage></ErrorPage>,
      loader: () => homePageLoader(),
      children: [
        {
          path: ROUTES.CLAIM_INTAKE_FORM,
          element: <ClaimIntakeFormPage />,
          loader: () => claimIntakeFormLoader(user),
        },
        {
          path: ROUTES.MY_SUBMITTED_CLAIMS,
          element: <MySubmittedClaimsPage />,
        },
        {
          path: ROUTES.MEDICAL_PANEL_DATA,
          element: <MedicalPanelDataPage />,
        },
        {
          path: ROUTES.SITE_CONFIGURATION,
          element: <SiteConfigurationPage />,
          loader: () => siteConfigurationLoader(user),
        },
        {
          path: ROUTES.DOCUMENTS_MANAGEMENT,
          element: <DocumentsManagementPage />,
          loader: () => siteConfigurationLoader(user),
        },
        {
          path: ROUTES.CLAIMS_HISTORY,
          element: <ClaimsHistoryPage />,
          loader: () => claimsHistoryLoader(user),
        },
        {
          path: ROUTES.WORKERS_COMPENSATION_DOCUMENTS,
          element: <WorkersCompensationDocumentsPage />,
        },
      ],
    },
  ]);
};
