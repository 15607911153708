import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { compareKeys } from "../../forms/tools";
import {
  GetWcFileCommandInput,
  ListWcFilesCommandInput,
  CreateWcFileUploadUrlCommandInput,
  WcFile,
} from "@amzn/ttechclaimintakeservice-client";
import { listFilesService } from "src/api/files/listFiles";
import { getFileService } from "src/api/files/getFile";
import {
  AUTHORIZATION_FORM_DOCUMENT_TYPE,
  MEDICAL_PANEL_DOCUMENT_TYPE,
  STATE_FORM_DOCUMENT_TYPE,
} from "src/api/files/constants";
import { createWcFileUploadUrlService } from "src/api/files/createWcFileUploadUrl";

interface DocumentTypeState {
  [index: string]: any;
  files: WcFile[];
  fileUrl: string;
  filesRetrieved: boolean;
  gettingFiles: boolean;
  fileUrlRetrieved: boolean;
  gettingFileUrl: boolean;
  listFilesError: string;
  getFileUrlError: string;
}
const initialDocumentTypeState: DocumentTypeState = {
  files: [],
  fileUrl: "",
  filesRetrieved: false,
  gettingFiles: false,
  fileUrlRetrieved: false,
  gettingFileUrl: false,
  listFilesError: "",
  getFileUrlError: "",
};
// Define a type for the slice state
export interface WorkersCompensationDocumentsState {
  [index: string]: DocumentTypeState;
  [MEDICAL_PANEL_DOCUMENT_TYPE]: DocumentTypeState;
  [AUTHORIZATION_FORM_DOCUMENT_TYPE]: DocumentTypeState;
  [STATE_FORM_DOCUMENT_TYPE]: DocumentTypeState;
}
export const initialState: WorkersCompensationDocumentsState = {
  [MEDICAL_PANEL_DOCUMENT_TYPE]: initialDocumentTypeState,
  [AUTHORIZATION_FORM_DOCUMENT_TYPE]: initialDocumentTypeState,
  [STATE_FORM_DOCUMENT_TYPE]: initialDocumentTypeState,
};

export const verifyStateIsValid = (
  state: WorkersCompensationDocumentsState
) => {
  return compareKeys(state, initialState);
};
/*eslint-disable @typescript-eslint/no-unused-vars*/
export const listFiles = createAsyncThunk(
  "workersCompensationDocuments/listFiles",
  async (input: ListWcFilesCommandInput, thunkAPI: any) => {
    const response = await listFilesService(input);
    return { ...response, fileType: input.fileType };
  }
);

export const getFile = createAsyncThunk(
  "workersCompensationDocuments/getFile",
  async (input: GetWcFileCommandInput, thunkAPI: any) => {
    const response = await getFileService(input);
    return { ...response, fileType: input.fileType };
  }
);

export const createWcFileUploadUrl = createAsyncThunk(
  "workersCompensationDocuments/createWcFileUploadUrl",
  async (input: CreateWcFileUploadUrlCommandInput, thunkAPI: any) => {
    const response = await createWcFileUploadUrlService(input);
    return { ...response, fileType: input.fileType };
  }
);
/*eslint-enable @typescript-eslint/no-unused-vars*/

export const workersCompensationDocumentsSlice = createSlice({
  name: "workersCompensationDocuments",
  initialState,
  reducers: {
    resetWorkersCompensationDocumentsState: () => {
      return initialState;
    },
    setNewWorkersCompensationDocumentsState: (
      state: WorkersCompensationDocumentsState,
      action: PayloadAction<{
        newState: DocumentTypeState;
        fileType: string;
      }>
    ) => {
      const { newState, fileType } = action.payload;

      return { ...state, [fileType]: newState };
    },
    setValueInWorkersCompensationDocumentsState: (
      state: WorkersCompensationDocumentsState,
      action: PayloadAction<{
        key: string;
        value: string | boolean | File[];
        fileType: string;
      }>
    ) => {
      const { fileType, key, value } = action.payload;
      state[fileType][key] = value;
    },
  },
  extraReducers: (builder: any) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(
        listFiles.fulfilled,
        (
          state: WorkersCompensationDocumentsState,
          action: PayloadAction<any>
        ) => {
          const { files, fileType } = action.payload;
          state[fileType].files = files;
          state[fileType].gettingFiles = false;
          state[fileType].filesRetrieved = true;
        }
      )
      .addCase(
        listFiles.pending,
        (state: WorkersCompensationDocumentsState, action: any) => {
          const { fileType } = action.meta.arg;
          state[fileType].gettingFiles = true;
          state[fileType].listFilesError = "";
        }
      )
      .addCase(
        listFiles.rejected,
        (state: WorkersCompensationDocumentsState, action: any) => {
          const { fileType } = action.meta.arg;
          state[fileType].gettingFiles = false;
          state[fileType].filesRetrieved = false;
          state.listFilesError = action.error.message;
        }
      )

      .addCase(
        getFile.fulfilled,
        (
          state: WorkersCompensationDocumentsState,
          action: PayloadAction<any>
        ) => {
          const { fileType, downloadUrl } = action.payload;
          state[fileType].fileUrl = downloadUrl;
          state[fileType].fileUrlRetrieved = true;
          state[fileType].gettingFileUrl = false;
          state[fileType].getFileUrlError = "";
        }
      )
      .addCase(
        getFile.pending,
        (state: WorkersCompensationDocumentsState, action: any) => {
          const { fileType } = action.meta.arg;
          state[fileType].gettingFileUrl = true;
          state[fileType].getFileUrlError = "";
        }
      )
      .addCase(
        getFile.rejected,
        (state: WorkersCompensationDocumentsState, action: any) => {
          const { fileType } = action.meta.arg;

          state[fileType].fileUrlRetrieved = false;
          state[fileType].gettingFileUrl = false;
          state.getFileUrlError = action.error.message;
        }
      )

      //Create Wc File Upload URL
      .addCase(
        createWcFileUploadUrl.fulfilled,
        (
          state: WorkersCompensationDocumentsState,
          action: PayloadAction<any>
        ) => {
          console.log(state, action);
        }
      )
      .addCase(
        createWcFileUploadUrl.pending,
        (state: WorkersCompensationDocumentsState, action: any) => {
          console.log(state, action);
        }
      )
      .addCase(
        createWcFileUploadUrl.rejected,
        (state: WorkersCompensationDocumentsState, action: any) => {
          console.log(state, action);
        }
      );
  },
});

export const {
  resetWorkersCompensationDocumentsState,
  setValueInWorkersCompensationDocumentsState,
  setNewWorkersCompensationDocumentsState,
} = workersCompensationDocumentsSlice.actions;

export default workersCompensationDocumentsSlice.reducer;
