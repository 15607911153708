import {
  ListWcFilesCommandOutput,
  ListWcFilesCommand,
  ListWcFilesCommandInput,
} from "@amzn/ttechclaimintakeservice-client";

import { getAuthenticationToken } from "../../auth/helpers";
import { setupClaimIntakeClient } from "../setup/setupClaimIntakeClient";
import { MetricsPublisher } from "../../metrics/metrics";

export async function listFilesService(input: ListWcFilesCommandInput) {
  const token = await getAuthenticationToken();
  if (!token) {
    Promise.reject(new Error("Unauthorized"));
  }
  const client = setupClaimIntakeClient(token);
  const command = new ListWcFilesCommand(input);
  const metricsPublisher = new MetricsPublisher("api.listFiles");

  return await client
    .send(command)
    .then((response: ListWcFilesCommandOutput) => {
      metricsPublisher.publishAuthenticationLatencyMetrics();
      metricsPublisher.publishRenderSuccessMetrics();
      return response;
    })
    .catch((error: any) => {
      metricsPublisher.publishAuthenticationLatencyMetrics();
      metricsPublisher.publishRenderFatalMetrics();
      throw error;
    });
}
