import * as KatalMetrics from "@katal/metrics";
import KatalMetricsDriverSushi from "@katal/metricsDriverSushi";
import KatalMetricsDriverConsoleLogJson from "@katal/metrics/lib/driver/KatalMetricsDriverConsoleLogJson";
import CONSTANTS from "../environmentInfo/Constants";
import { FeatureFlag } from "./FeatureFlag";
import { getEnvironment } from "../environmentInfo/EnvironmentInfo";
export class MetricsPublisherProvider {
  private static publisher: KatalMetrics.Publisher;

  private static metricsConsoleErrorHandler(err: Error): void {
    console.error(err);
  }

  private static getMetricsDriver(): KatalMetrics.MetricsDriver {
    const setToSushiProd = ["gamma", "prod"];
    const environment: string = getEnvironment();
    const sushiEnvironment = setToSushiProd.includes(environment)
      ? "prod"
      : "test";

    if (environment === "local") {
      return new KatalMetricsDriverConsoleLogJson();
    } else {
      return new KatalMetricsDriverSushi.Builder()
        .withDomainRealm(sushiEnvironment, CONSTANTS.REALM.USAmazon)
        .withErrorHandler(this.metricsConsoleErrorHandler)
        .build();
    }
  }

  private static getMetricsPublisher(): KatalMetrics.Publisher {
    if (this.publisher === undefined) {
      const metricsDriver = this.getMetricsDriver();
      const environment: string = getEnvironment();

      const siteName =
        environment === "gamma"
          ? CONSTANTS.APPLICATION_NAME.ClaimMetricsSiteName + "-Gamma"
          : CONSTANTS.APPLICATION_NAME.ClaimMetricsSiteName;
      const initialMetricsContext = new KatalMetrics.Context.Builder()
        .withSite(siteName)
        .withServiceName(CONSTANTS.APPLICATION_NAME.ClaimsMetricsServiceName)
        .build();

      this.publisher = new KatalMetrics.Publisher(
        metricsDriver,
        this.metricsConsoleErrorHandler,
        initialMetricsContext
      );
    }
    return this.publisher;
  }

  public static getMetricsPublisherForMethod(
    methodName: string
  ): KatalMetrics.Publisher {
    return MetricsPublisherProvider.getMetricsPublisher().newChildActionPublisherForMethod(
      methodName
    );
  }
}

export class MetricsPublisher {
  private metricsPublisher: KatalMetrics.Publisher;
  private startTime: number;

  public constructor(methodName: string) {
    this.metricsPublisher =
      MetricsPublisherProvider.getMetricsPublisherForMethod(methodName);
    this.startTime = Date.now();
  }

  public publishAuthenticationLatencyMetrics(): void {
    this.metricsPublisher.publishTimerMonitor(
      "AuthenticationLatency",
      Date.now() - this.startTime
    );
  }

  public publishSubmitClaimLatencyMetrics(): void {
    this.metricsPublisher.publishTimerMonitor(
      "submitClaimLatency",
      Date.now() - this.startTime
    );
  }

  public publishFileUploadLatencyMetrics(): void {
    this.metricsPublisher.publishTimerMonitor(
      "fileUploadLatency",
      Date.now() - this.startTime
    );
  }

  public publishFileUploadURLLatencyMetrics(): void {
    this.metricsPublisher.publishTimerMonitor(
      "fileUploadURLLatency",
      Date.now() - this.startTime
    );
  }

  public publishDuplicateClaimAttemptMetric(userName: any) {
    this.metricsPublisher.publishCounterMonitor(userName, 1);
  }

  public publishVisited(): void {
    this.metricsPublisher.publishCounterMonitor("visited", 1);
  }
  public publishUserName(userName: string): void {
    this.metricsPublisher.publishCounterMonitor(userName, 1);
  }

  public publishClaimSubmittedSuccessMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("claimSubmitSuccess", 1);
  }
  public publishClaimSubmittedSuccessSedgwickMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("claimSuccessSedgwick", 1);
  }
  public publishClaimSubmittedSuccessAnchorMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("claimSuccessAnchor", 1);
  }
  public publishClaimSubmittedSuccessHelmsmanMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("claimSuccessHelmsman", 1);
  }

  public publishSiteOfSubmittedClaim(siteName: any): void {
    this.metricsPublisher.publishCounterMonitor(siteName, 1);
  }
  public publishClaimSubmittedFailure(): void {
    this.metricsPublisher.publishCounterMonitor("claimSubmittedFailure", 1);
  }
  public publishClaimSubmittedServerFailureMetrics(): void {
    this.metricsPublisher.publishCounterMonitor(
      "claimSubmittedServerFailure",
      1
    );
  }
  public publishClaimSubmittedClientErrorMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("claimSubmittedClientError", 1);
  }
  public publishClaimSubmittedTimeoutMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("claimSubmittedTimeout", 1);
  }

  public publishClaimSubmittedErrorCodeMetrics(errorCode: string): void {
    this.metricsPublisher.publishCounterMonitor(errorCode, 1);
  }

  public claimValidationError(): void {
    this.metricsPublisher.publishCounterMonitor("claimValidationError", 1);
  }

  public publishFileUploadSuccessMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("fileUploadSuccess", 1);
  }

  public publishFileUploadFailureMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("fileUploadFailure", 1);
  }

  public publishFileUploadURLSuccessMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("fileUploadURLSuccess", 1);
  }

  public publishFileUploadURLFailureMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("fileUploadURLFailure", 1);
  }

  public publishAuthenticationSuccessMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("authenticationSuccess", 1);
  }

  public publishAuthenticationFailureMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("authenticationFailure", 1);
  }

  public publishRenderSuccessMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("fatal", 0);
    this.metricsPublisher.publishCounterMonitor("success", 1);
  }

  public publishDynamicUIRenderSuccessMetrics(): void {
    const successLogging: boolean = FeatureFlag["katalLoggerSuccessLogging"];
    const environment: string = getEnvironment();

    if (
      successLogging &&
      !(
        environment === "local" ||
        environment === "beta" ||
        environment === "alpha"
      )
    ) {
      this.metricsPublisher.publishCounterMonitor("fatal", 0);
      this.metricsPublisher.publishCounterMonitor("success", 1);
    }
  }

  public publishNextOrPrevStepTransitionLatencyMetrics(
    transitionDirection: string,
    time: number
  ): void {
    this.metricsPublisher.publishTimerMonitor(
      `${transitionDirection}_latency`,
      time
    );
  }

  public publishRenderFatalMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("fatal", 1);
    this.metricsPublisher.publishCounterMonitor("success", 0);
  }

  public publishFatalMetric(): void {
    this.metricsPublisher.publishCounterMonitor("fatal", 1);
  }

  public publishNamedCounterMetric(name: string, value: number): void {
    this.metricsPublisher.publishCounterMonitor(name, value);
  }

  public publishTimeStayOnPageMetrics(): void {
    this.metricsPublisher.publishTimerMonitor(
      "last_time",
      Date.now() - this.startTime
    );
  }

  public publishIncidentSiteDifferentThanHome(): void {
    this.metricsPublisher.publishCounterMonitor(
      "IncidentSiteDifferentThanHome",
      1
    );
  }

  public publishSubmitClaimError(value: string): void {
    this.metricsPublisher.publishStringTruncate("error", value);
  }

  public publishAuthenticationError(value: string): void {
    this.metricsPublisher.publishStringTruncate("error", value);
  }

  public publishCreateClaimURLError(value: string): void {
    this.metricsPublisher.publishStringTruncate("error", value);
  }

  public publishFileUploadError(value: string): void {
    this.metricsPublisher.publishStringTruncate("error", value);
  }

  public publishAuthenticationTokenValidMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("authenticationTokenValid", 1);
  }

  public publishAuthenticationTokenInvalidMetrics(): void {
    this.metricsPublisher.publishCounterMonitor(
      "authenticationTokenInValid",
      1
    );
  }

  public publishFileUploadURLRetryMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("fileUploadURLRetry", 1);
  }

  public publishFileUploadRetryMetrics(): void {
    this.metricsPublisher.publishCounterMonitor("fileUploadRetry", 1);
  }

  public publishDuplicateClaimWarningResultsMetrics(userName: any): void {
    this.metricsPublisher.publishCounterMonitor(`${userName}`, 1);
  }
  public publishMedicalPanelDataVisited(): void {
    this.metricsPublisher.publishCounterMonitor("medicalPanelDataVisited", 1);
  }
  public publishMedicalPanelDataVisitedBySite(siteName: any): void {
    this.metricsPublisher.publishCounterMonitor(siteName, 1);
  }
  public publishMedicalPanelDataDownloads(): void {
    this.metricsPublisher.publishCounterMonitor("medicalPanelDataDownloads", 1);
  }
  public publishMedicalPanelDataDownloadsBySite(siteName: any): void {
    this.metricsPublisher.publishCounterMonitor(siteName, 1);
  }
  public publishMedicalPanelDataMatchSuccess(): void {
    this.metricsPublisher.publishCounterMonitor(
      "medicalPanelDataMatchSuccess",
      1
    );
  }
  public publishMedicalPanelDataMatchNotFound(siteName: any): void {
    this.metricsPublisher.publishCounterMonitor(siteName, 1);
  }
  public publishLoadMedicalPanelDataPageLatency(): void {
    this.metricsPublisher.publishTimerMonitor(
      "loadMedicalPanelDataPageLatency",
      Date.now() - this.startTime
    );
  }
  public publishMedicalPanelDataNonMatchingDownload(
    siteNameAndFileName: any
  ): void {
    this.metricsPublisher.publishCounterMonitor(siteNameAndFileName, 1);
  }

  // Search Claims Metrics

  public publishSearchResultsVisited(): void {
    this.metricsPublisher.publishCounterMonitor("searchResultsVisited", 1);
  }
  public publishSearchResultsVisitedBySite(siteName: any): void {
    this.metricsPublisher.publishCounterMonitor(siteName, 1);
  }
  public publishMySubmittedClaimsVisited(): void {
    this.metricsPublisher.publishCounterMonitor("mySubmittedClaimsVisited", 1);
  }
  public publishMySubmittedClaimsVisitedBySite(siteName: any): void {
    this.metricsPublisher.publishCounterMonitor(siteName, 1);
  }
  public publishClaimsSearchBarUsed(): void {
    this.metricsPublisher.publishCounterMonitor("claimsSearchBarUsed", 1);
  }
  public publishClaimsSearchBarUsedBySite(siteName: any): void {
    this.metricsPublisher.publishCounterMonitor(siteName, 1);
  }
  public publishClaimsSearchByEmployeeId(employeeId: any): void {
    this.metricsPublisher.publishCounterMonitor(employeeId, 1);
  }
  public publishClaimsSearchByAlias(alias: any): void {
    this.metricsPublisher.publishCounterMonitor(alias, 1);
  }
  public publishClaimsSearchBySite(site: any): void {
    this.metricsPublisher.publishCounterMonitor(site, 1);
  }
  public publishClaimsSearchDateRangeUsed(): void {
    this.metricsPublisher.publishCounterMonitor("claimsSearchDateRangeUsed", 1);
  }

  // Workers Compensation Documents

  public publishWorkersCompensationDocumentsVisited(): void {
    this.metricsPublisher.publishCounterMonitor(
      "WorkersCompensationDocumentsVisited",
      1
    );
  }
  public publishWorkersCompensationDocumentsVisitedBySite(siteName: any): void {
    this.metricsPublisher.publishCounterMonitor(siteName, 1);
  }
  public publishWorkersCompensationDocumentsDownloads(): void {
    this.metricsPublisher.publishCounterMonitor(
      "WorkersCompensationDocumentsDownloads",
      1
    );
  }
  public publishWorkersCompensationDocumentsDownloadsBySite(
    siteName: any
  ): void {
    this.metricsPublisher.publishCounterMonitor(siteName, 1);
  }
  public publishWorkersCompensationDocumentsMatchSuccess(): void {
    this.metricsPublisher.publishCounterMonitor(
      "WorkersCompensationDocumentsMatchSuccess",
      1
    );
  }
  public publishWorkersCompensationDocumentsMatchNotFound(siteName: any): void {
    this.metricsPublisher.publishCounterMonitor(siteName, 1);
  }
  public publishLoadWorkersCompensationDocumentsPageLatency(): void {
    this.metricsPublisher.publishTimerMonitor(
      "loadWorkersCompensationDocumentsPageLatency",
      Date.now() - this.startTime
    );
  }
  public publishWorkersCompensationDocumentsNonMatchingDownload(
    siteNameAndFileName: any
  ): void {
    this.metricsPublisher.publishCounterMonitor(siteNameAndFileName, 1);
  }
}
