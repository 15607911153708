import {
  addError,
  checkIfFieldIsEmpty,
  checkIfFieldsAreEmptyOrHidden,
  removeError,
} from "src/forms/tools";
import {
  CUSTOM_VALIDATION_TYPES,
  FormFieldStateType,
  FormFieldType,
  FormStateType,
  INPUT_TYPES,
  ValidationContentsType,
} from "src/forms/types";
import moment from "moment";
import { validateDateTime } from "./validations";

/**
 * Compares two dates based on a specified validation type.
 *
 * @param unparsedDate1 - The first date in string format (YYYY/MM/DD).
 * @param unparsedDate2 - The second date in string format (YYYY/MM/DD).
 * @param validationType - The type of validation to be performed.
 * @returns A boolean value indicating the result of the comparison, or the string "error" if either date is invalid.
 */
function compareDates(
  unparsedDate1: string,
  unparsedDate2: string,
  validationType: CUSTOM_VALIDATION_TYPES
): boolean | "error" {
  const date1 = moment(unparsedDate1, "YYYY/MM/DD");
  const date2 = moment(unparsedDate2, "YYYY/MM/DD");
  if (!date1.isValid() || !date2.isValid()) {
    return "error";
  }
  if (validationType === CUSTOM_VALIDATION_TYPES.DATE_IS_AFTER_OR_EQUAL) {
    return moment(date1).isSameOrAfter(moment(date2), "day");
  }
  if (validationType === CUSTOM_VALIDATION_TYPES.DATE_IS_BEFORE_OR_EQUAL) {
    return moment(date1).isSameOrBefore(moment(date2), "day");
  }
  return false;
}

/**
 * Compares two date-time objects based on a specified validation type.
 *
 * @param dateTime1 - An object containing the date and time for the first date-time value.
 * @param dateTime2 - An object containing the date and time for the second date-time value.
 * @param validationType - The type of validation to be performed.
 * @returns A boolean value indicating the result of the comparison, or the string "error" if any of the input values are invalid or missing.
 */
function compareDateTimes(
  dateTime1: { date: string; time: string },
  dateTime2: { date: string; time: string },
  validationType: CUSTOM_VALIDATION_TYPES
): boolean | "error" {
  if (
    !dateTime1?.date ||
    !dateTime1?.time ||
    !dateTime2?.date ||
    !dateTime2?.time
  ) {
    return "error";
  }
  const parsedDateTime1 = moment(
    `${dateTime1.date} ${dateTime1.time}`,
    "YYYY-MM-DD HH:mm",
    true
  );
  const parsedDateTime2 = moment(
    `${dateTime2.date} ${dateTime2.time}`,
    "YYYY-MM-DD HH:mm",
    true
  );
  if (!parsedDateTime1.isValid() || !parsedDateTime2.isValid()) {
    return "error";
  }
  if (validationType === CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_AFTER) {
    return moment(parsedDateTime1).isSameOrAfter(
      moment(parsedDateTime2),
      "minute"
    );
  }
  if (validationType === CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_BEFORE) {
    return moment(parsedDateTime1).isSameOrBefore(
      moment(parsedDateTime2),
      "minute"
    );
  }
  return false;
}

/**
 * Helper function to get the combined values when validation is VALID_COMBINED_VALUE or
 * DIFFERENT_COMBINED_VALUE.
 *
 * @param primaryField Field to validate.
 * @param fieldToJoinWith Field that will be combined with the primary field
 * @param primaryFieldValidationOrder Order to combine the primary field in "first" or "second"
 * @param firstFieldToCompareTo First field to generate the combinedValueToCompareTo
 * @param secondFieldToCompareTo Second field to generate the combinedValueToCompareTo
 * @returns Object with the combined values
 */
function getCombinedValues(
  primaryField: FormFieldStateType,
  fieldToJoinWith: FormFieldStateType,
  primaryFieldValidationOrder: "first" | "second",
  firstFieldToCompareTo?: FormFieldStateType,
  secondFieldToCompareTo?: FormFieldStateType
) {
  const firstField =
    primaryFieldValidationOrder === "first" ? primaryField : fieldToJoinWith;
  const secondField =
    primaryFieldValidationOrder === "second" ? primaryField : fieldToJoinWith;

  const firstValue = firstField?.value?.value || firstField?.value;
  const secondValue = secondField?.value?.value || secondField?.value;

  const firstValueToCompareTo =
    firstFieldToCompareTo?.value?.value || firstFieldToCompareTo?.value;

  const secondValueToCompareTo =
    secondFieldToCompareTo?.value?.value || secondFieldToCompareTo?.value;

  const combinedValue = firstValue + secondValue;
  const combinedValueToCompareTo =
    firstValueToCompareTo + secondValueToCompareTo;

  return { combinedValue, combinedValueToCompareTo };
}
/**
 * Validates field when it includes the custom validation for VALID_COMBINED_VALUE and
 * returns the validated fields in the validatedFields object
 *
 * @param field Field to validate.
 * @param formState Current formState.
 * @param validatedFields Object to add the validated fields result
 */
function validateCombinedValues(
  field: FormFieldType,
  formState: FormStateType,
  validatedFields: FormStateType
) {
  const validationType = CUSTOM_VALIDATION_TYPES.VALID_COMBINED_VALUE;
  const validation = field.validation[validationType];

  if (!validation) {
    return;
  }
  const fieldStateToJoinWith: FormFieldStateType = {
    ...formState[validation?.joinWith?.value],
  };
  if (
    checkIfFieldIsEmpty(field.state) ||
    checkIfFieldIsEmpty(fieldStateToJoinWith)
  ) {
    return;
  }
  const { combinedValue } = getCombinedValues(
    field.state,
    fieldStateToJoinWith,
    validation.order
  );

  if (!validation.acceptedValuesList.includes(combinedValue)) {
    addError(validation.message, field.state.errors!);
    addError(validation.joinWith.message, fieldStateToJoinWith!.errors!);
  } else {
    removeError(validation.message!, field.state.errors!);
    removeError(validation.joinWith.message, fieldStateToJoinWith.errors!);
  }
  validatedFields[validation.joinWith.value as keyof FormStateType] =
    fieldStateToJoinWith;
}

/**
 * Validates field when it includes the custom validation for DIFFERENT_COMBINED_VALUE and
 * returns the validated fields in the validatedFields object
 *
 * @param field Field to validate.
 * @param formState Current formState.
 * @param validatedFields Object to add the validated fields result
 */
function validateCombinedValuesAreDifferent(
  field: FormFieldType,
  formState: FormStateType,
  validatedFields: FormStateType
) {
  const validationType = CUSTOM_VALIDATION_TYPES.DIFFERENT_COMBINED_VALUE;
  const validation = field.validation[validationType];

  if (!validation) {
    return;
  }
  const fieldStateToJoinWith: FormFieldStateType = {
    ...formState[validation?.joinWith?.value],
  };
  const firstFieldToCompareTo: FormFieldStateType = {
    ...formState[validation?.compareWith?.first?.value],
  };
  const secondFieldToCompareTo: FormFieldStateType = {
    ...formState[validation?.compareWith?.second?.value],
  };

  // Remove errors first to prevent old errors to remain set
  // when a field if found to be empty or hidden and validation
  // should not be checked
  removeError(validation.message!, field.state.errors!);
  removeError(validation.joinWith.message, fieldStateToJoinWith.errors!);
  removeError(
    validation.compareWith.first.message,
    firstFieldToCompareTo!.errors!
  );
  removeError(
    validation.compareWith.second.message,
    secondFieldToCompareTo!.errors!
  );
  if (
    checkIfFieldsAreEmptyOrHidden([
      field.state,
      fieldStateToJoinWith,
      firstFieldToCompareTo,
      secondFieldToCompareTo,
    ])
  ) {
    validatedFields[validation.joinWith.value as keyof FormStateType] =
      fieldStateToJoinWith;
    validatedFields[validation.compareWith.first.value as keyof FormStateType] =
      firstFieldToCompareTo;
    validatedFields[
      validation.compareWith.second.value as keyof FormStateType
    ] = secondFieldToCompareTo;
    return;
  }
  const { combinedValue, combinedValueToCompareTo } = getCombinedValues(
    field.state,
    fieldStateToJoinWith,
    validation.order,
    firstFieldToCompareTo,
    secondFieldToCompareTo
  );
  if (combinedValue === combinedValueToCompareTo) {
    addError(validation.message, field.state.errors!);
    addError(validation.joinWith.message, fieldStateToJoinWith!.errors!);
    addError(
      validation.compareWith.first.message,
      firstFieldToCompareTo!.errors!
    );

    addError(
      validation.compareWith.second.message,
      secondFieldToCompareTo!.errors!
    );
  }
  validatedFields[validation.joinWith.value as keyof FormStateType] =
    fieldStateToJoinWith;
  validatedFields[validation.compareWith.first.value as keyof FormStateType] =
    firstFieldToCompareTo;
  validatedFields[validation.compareWith.second.value as keyof FormStateType] =
    secondFieldToCompareTo;
}
/**
 * Validates field when it includes the custom validation for AT_LEAST_ONE_REQUIRED and
 * returns the validated fields in the validatedFields object
 *
 * @param field Field to validate.
 * @param formState Current formState.
 * @param validatedFields Object to add the validated fields result
 */
function validateAtLeastOneRequired(
  field: FormFieldType,
  formState: FormStateType,
  validatedFields: FormStateType
) {
  const validation: ValidationContentsType | undefined =
    field.validation[CUSTOM_VALIDATION_TYPES.AT_LEAST_ONE_REQUIRED];

  if (!validation?.joinWith?.value) {
    return;
  }
  const fieldStateToJoinWith: FormFieldStateType = {
    ...formState[validation?.joinWith?.value],
  };
  if (!fieldStateToJoinWith) {
    return;
  }
  if (
    checkIfFieldIsEmpty(field.state) &&
    checkIfFieldIsEmpty(fieldStateToJoinWith)
  ) {
    if (!validation.customError && !validation.joinWith.customError) {
      addError(validation.message, field.state.errors!);
      addError(validation.joinWith.message, fieldStateToJoinWith!.errors!);
    } else {
      field.state.showCustomError = !!validation.customError;
      fieldStateToJoinWith.showCustomError = !!validation.joinWith.customError;
    }
  } else {
    removeError(validation.message!, field.state.errors!);
    removeError(validation.joinWith.message, fieldStateToJoinWith.errors!);
    field.state.showCustomError = false;
    fieldStateToJoinWith.showCustomError = false;
  }
  validatedFields[validation.joinWith.value as keyof FormStateType] =
    fieldStateToJoinWith;
}

/**
 * Validates field when it includes the custom validation for DATE_IS_AFTER_OR_EQUAL or
 * DATE_IS_BEFORE_OR_EQUAL and returns the validated fields in the validatedFields object.
 *
 * @param field Field to validate.
 * @param formState Current formState.
 * @param validatedFields Object to add the validated fields result
 */
function validateDateBeforeOrAfter(
  field: FormFieldType,
  formState: FormStateType,
  validatedFields: FormStateType
) {
  let validationType;

  if (field.validation[CUSTOM_VALIDATION_TYPES.DATE_IS_AFTER_OR_EQUAL]) {
    validationType = CUSTOM_VALIDATION_TYPES.DATE_IS_AFTER_OR_EQUAL;
  } else {
    validationType = CUSTOM_VALIDATION_TYPES.DATE_IS_BEFORE_OR_EQUAL;
  }
  const validation: ValidationContentsType | undefined =
    field.validation[validationType];
  if (!validation?.compareTo?.value) {
    return;
  }
  const fieldStateToCompareTo: FormFieldStateType = {
    ...formState[validation.compareTo.value],
  };

  if (
    checkIfFieldIsEmpty(field.state) ||
    checkIfFieldIsEmpty(fieldStateToCompareTo)
  ) {
    return;
  }

  const datesAreValid = compareDates(
    field.state.value,
    fieldStateToCompareTo.value,
    validationType
  );
  if (datesAreValid === false) {
    addError(validation.message, field.state.errors!);
    addError(
      validation.compareTo.message || validation.message,
      fieldStateToCompareTo.errors
    );
  } else if (datesAreValid === true) {
    removeError(validation.message, field.state.errors!);
    removeError(
      validation.compareTo.message || validation.message,
      fieldStateToCompareTo.errors
    );
  }
  validatedFields[validation.compareTo.value as keyof FormStateType] =
    fieldStateToCompareTo;
}

/**
 * Validates field when it includes the custom validation for DATE_TIME_IS_NOT_IN_FUTURE and
 * returns the validated fields in the validatedFields object.
 *
 * @param field Field to validate.
 * @param formState Current formState.
 * @param validatedFields Object to add the validated fields result
 */
function validateDateTimeNotInTheFuture(
  field: FormFieldType,
  formState: FormStateType,
  validatedFields: FormStateType
) {
  const validation =
    field.validation[CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_NOT_IN_FUTURE];

  if (!validation) {
    return;
  }
  const fieldStateToJoinWith: FormFieldStateType = {
    ...formState[validation.joinWith.value],
  };
  if (
    checkIfFieldIsEmpty(field.state) ||
    checkIfFieldIsEmpty(fieldStateToJoinWith)
  ) {
    return;
  }
  let dateTime = { date: "", time: "" };
  if (field.config.type === INPUT_TYPES.TIME) {
    dateTime = {
      date: fieldStateToJoinWith.value,
      time: field.state.value,
    };
  } else {
    dateTime = {
      date: field.state.value,
      time: fieldStateToJoinWith.value,
    };
  }
  if (
    validateDateTime(
      dateTime,
      CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_NOT_IN_FUTURE
    ) === false
  ) {
    addError(validation.message, field.state.errors!);
    addError(
      validation.joinWith.message || validation.message,
      fieldStateToJoinWith.errors!
    );
  } else {
    removeError(validation.message, field.state.errors!);
    removeError(
      validation.joinWith.message || validation.message,
      fieldStateToJoinWith.errors!
    );
  }
  validatedFields[validation.joinWith.value as keyof FormStateType] =
    fieldStateToJoinWith;
}

/**
 * Validates field when it includes the custom validation for DATE_TIME_IS_AFTER or
 * DATE_TIME_IS_BEFORE and returns the validated fields in the validatedFields object.
 *
 * @param field Field to validate.
 * @param formState Current formState.
 * @param validatedFields Object to add the validated fields result
 */

function validateDateTimeBeforeOrAfter(
  field: FormFieldType,
  formState: FormStateType,
  validatedFields: FormStateType
) {
  let validationType:
    | CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_AFTER
    | CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_BEFORE;

  if (field.validation[CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_AFTER]) {
    validationType = CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_AFTER;
  } else {
    validationType = CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_BEFORE;
  }
  const validation = field.validation[validationType];
  if (!validation) {
    return;
  }
  const fieldsToValidate = {
    fieldStateToValidate: field.state,
    fieldStateToJoinWith: {
      ...formState[validation.joinWith!.value!],
    },
    dateFieldStateToCompareTo: {
      ...formState[validation.compareDate.value],
    },
    timeFieldStateToCompareTo: {
      ...formState[validation.compareTime.value],
    },
  };
  for (const fieldState of Object.values(fieldsToValidate)) {
    if (checkIfFieldIsEmpty(fieldState)) {
      return;
    }
  }

  const {
    fieldStateToJoinWith,
    dateFieldStateToCompareTo,
    timeFieldStateToCompareTo,
  } = fieldsToValidate;

  let dateTime = { date: "", time: "" };
  const dateTimeToCompare = {
    date: dateFieldStateToCompareTo.value,
    time: timeFieldStateToCompareTo.value,
  };
  if (field.config.type === INPUT_TYPES.TIME) {
    dateTime = {
      date: fieldStateToJoinWith.value,
      time: field.state.value,
    };
  } else {
    dateTime = {
      date: field.state.value,
      time: fieldStateToJoinWith.value,
    };
  }
  const dateTimesAreValid = compareDateTimes(
    dateTime,
    dateTimeToCompare,
    validationType
  );

  if (dateTimesAreValid === false) {
    addError(validation.message, field.state.errors!);
    addError(validation.joinWith.message, fieldStateToJoinWith.errors!);
    addError(validation.compareDate.message, dateFieldStateToCompareTo.errors!);
    addError(validation.compareTime.message, timeFieldStateToCompareTo.errors!);
  } else if (dateTimesAreValid === true) {
    removeError(validation.message, field.state.errors!);
    removeError(validation.joinWith.message, fieldStateToJoinWith.errors!);
    removeError(
      validation.compareDate.message,
      dateFieldStateToCompareTo.errors!
    );
    removeError(
      validation.compareTime.message,
      timeFieldStateToCompareTo.errors!
    );
  }
  validatedFields[validation.joinWith.value as keyof FormStateType] =
    fieldStateToJoinWith;
  validatedFields[validation.compareDate.value as keyof FormStateType] =
    dateFieldStateToCompareTo;
  validatedFields[validation.compareTime.value as keyof FormStateType] =
    timeFieldStateToCompareTo;
}
/**
 * Performs a combination of validations on a form field based on the form state.
 *
 * @param field - The form field to be validated.
 * @param formState - The current state of the form.
 * @returns An object containing the validated fields and their states.
 */
export function combinedValidations(
  field: FormFieldType,
  formState: FormStateType
) {
  const validatedFields: FormStateType = {};
  validateAtLeastOneRequired(field, formState, validatedFields);
  validateDateBeforeOrAfter(field, formState, validatedFields);
  validateDateTimeNotInTheFuture(field, formState, validatedFields);
  validateDateTimeBeforeOrAfter(field, formState, validatedFields);
  validateCombinedValues(field, formState, validatedFields);
  validateCombinedValuesAreDifferent(field, formState, validatedFields);
  validatedFields[field.config.uniqueName || field.config.name] = field.state;
  return validatedFields;
}
